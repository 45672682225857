<template>
    <div class="m-0 v-100 row justify-content-center align-items-center" style="position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;">
        <div class="triangulo"></div>
        <div class="row" style="margin-top: 100px;">
            <div class="col-md-6 d-flex justify-content-center">
                <!--Ruletas-->
                <img :src="Ruleta1Img" id="ruleta" @click="spingRulete" style="cursor:pointer" v-if="!teams.rulete" class="rulete-img">
                <img :src="Ruleta2Img" id="ruleta" @click="spingRulete" style="cursor:pointer" v-else class="rulete-img">

            </div>
            <div class="col-md-6 d-flex justify-content-center">
                <!--Leyendas Ruleta 2-->
                <img :src="Leyenda1EnglishPm" @click="spingRulete" v-if="teams.rulete && teams.lenguage == 'english'" style="height: 750px;">
                <img :src="Leyenda1TurkishPm" @click="spingRulete" v-if="teams.rulete && teams.lenguage == 'turkish'" style="height: 750px;">
                <img :src="Leyenda1GreekPm" @click="spingRulete" v-if="teams.rulete && teams.lenguage == 'grece'" style="height: 750px;">
                <img :src="Leyenda1SpanishPm" @click="spingRulete" v-if="teams.rulete && teams.lenguage == 'spanish'" style="height: 750px;">

                <!--Leyendas Ruleta 1-->
                <img :src="Leyenda2EnglishPm" @click="spingRulete" v-if="!teams.rulete && teams.lenguage == 'english'" style="height: 750px;">
                <img :src="Leyenda2TurkishPm" @click="spingRulete" v-if="!teams.rulete && teams.lenguage == 'turkish'" style="height: 750px;">
                <img :src="Leyenda2GreekPm" @click="spingRulete" v-if="!teams.rulete && teams.lenguage == 'grece'" style="height: 750px;">
                <img :src="Leyenda2SpanishPm" @click="spingRulete" v-if="!teams.rulete && teams.lenguage == 'spanish'" style="height: 750px;">
            </div>

        </div>  

        <div>
            <div id="sonido" style="display: none;">
            <iframe :src="SonidoRuleta" id="audio"></iframe>
            </div>
        </div>
    </div>
</template>
<style scoped>
.triangulo {
    width: 0;
    height: 0;
    border-right: 100px solid #EE022D;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    position: absolute;
    top: 616px;
    transform: rotate(90deg);
    left: 398px;
    z-index: 333333333333;
}

.rulete-img {
    z-index: 100;
    transition: all 5s;
    cursor: pointer;
    position: relative;
    width: 70%;
    transform: rotate(4424.33deg);
    inset: 0px;
    margin: auto;
}


</style>

<script>
import Ruleta1Img from '@/assets/img/ruletaRosada.png';
import Ruleta2Img from '@/assets/img/ruletaGreen.png';
import SonidoRuleta from '@/assets/sonido/ruleta.mp3';

//leyendas

//EN
import Leyenda1EnglishPm from '@/assets/img/leyenda1En.png';
import Leyenda2EnglishPm from '@/assets/img/leyenda2En.png';

//TR
import Leyenda1TurkishPm from '@/assets/img/leyenda1Tr.png';
import Leyenda2TurkishPm from '@/assets/img/leyenda2Tr.png';

//ES
import Leyenda1SpanishPm from '@/assets/img/leyenda1Es.png';
import Leyenda2SpanishPm from '@/assets/img/leyenda2Es.png';

//GR
import Leyenda1GreekPm from '@/assets/img/leyenda1Gr.png';
import Leyenda2GreekPm from '@/assets/img/leyenda2Gr.png';

export default {
    props: ['teams'],
    data: () => ({
        giros: 0,
        Ruleta1Img,
        Ruleta2Img,
        SonidoRuleta,
        Leyenda1EnglishPm,
        Leyenda2EnglishPm,
        Leyenda1TurkishPm,
        Leyenda2TurkishPm,
        Leyenda1GreekPm,
        Leyenda2GreekPm,
        Leyenda1SpanishPm,
        Leyenda2SpanishPm
    }),
    methods: {
        changeBoardView() {
            return true;
        }
    }, 

    mounted() {

    },
    methods: {
        spingRulete() {
            let rand = Math.random() * 7200;
            this.calcular(rand);
            var sonido = document.querySelector('#audio');
            sonido.setAttribute('src', this.SonidoRuleta);
        },
        calcular(rand)  {

            let value = rand / 360;
            let questionId = null;
            value = (value - parseInt(value.toString().split(".")[0]))* 360;
            const ruleta = document.getElementById("ruleta");
            ruleta.style.transform = "rotate("+rand+"deg)";

            if(!this.teams.rulete) {
                switch(true) {
                    case value > 0 && value <= 45:
                        questionId = 1;
                        console.log("description");
                        break;
                    case value > 45 && value <= 90:
                        questionId = 4;
                        console.log("intersection");
                        break;                
                    case value > 90 && value <= 135:
                        questionId = 6;
                        console.log("difference");
                        break;
                    case value > 135 && value <= 180:
                        questionId = 5;
                        console.log("complementari");
                        break;
                    case value > 180 && value <= 225:
                        questionId = 7;
                        console.log("180 -225");
                        break;
                    case value > 225 && value <= 270:
                        questionId = 3;
                        console.log("225 - 270");
                        break;
                    case value > 270 && value <= 315:
                        questionId = 0;
                        console.log("270 - 315");
                        break;
                    case value > 315 && value <= 360:
                        questionId = 2;
                        console.log("315 - 360");
                        break;
                }
            } else {
                switch(true) {
                    case value > 0 && value <= 45:
                        questionId = 1;
                        console.log("and");
                        break
                    case value > 45 && value <= 90:
                        questionId = 4;
                        console.log("convention");
                        break;                
                    case value > 90 && value <= 135:
                        questionId = 6;
                        console.log("proof");
                        break;
                    case value > 135 && value <= 180:
                        questionId = 5;
                        console.log("bonus");
                        break;
                    case value > 180 && value <= 225:
                        questionId = 7;
                        console.log("clear");
                        break;
                    case value > 225 && value <= 270:
                        questionId = 3;
                        console.log("or");
                        break;
                    case value > 270 && value <= 315:
                        questionId = 2;
                        console.log("accuracy");
                        break;
                    case value > 315 && value <= 360:
                        questionId = 1;
                        console.log("determination");
                        break;
                }
            }
            
            setTimeout(() => {
                this.teams.ruleteMode = false;
                this.teams.questionView = true;
                this.teams.questionSelected = questionId;
            }, 6000) 

        }
    }
}
</script>
const questions = [
    [
        {
            id: '0',
            name: "BONUS",
            options: [
                {
                    nameQuestion: 'What do you call the statements that give a true or false final judgement?',
                    answers: ['Proposition', 'Proposal', 'Judgement', 'Experiment'],
                    answerCorect: 'Proposition'
                },
                {
                    nameQuestion: 'Does the question “Turkey&#39;s capital city is Istanbul ” indicate a proposition?',
                    answers: ['Not a proposıtıon ', 'It is a proposition'],
                    answerCorect: 'Not a proposıtıon '
                },
                {
                    nameQuestion: 'Does the question “A week is seven days ” indicate a proposition?',
                    answers: ['It is a proposition', 'It is not a proposition'],
                    answerCorect: 'It is a proposition'
                },
                {
                    nameQuestion: 'Does the question “Where are you going ?” indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'What is a proposition?',
                    answers: ['Statements that state a definite judgement, true or false,are called propositions', 'Statements that make only true judgments are called propositions', 'Proposition only for statements that express a false judgment', ' Statements whose truth is not certain are called propositions'],
                    answerCorect: 'Statements that state a definite judgement, true or false,are called propositions'
                },
                {
                    nameQuestion: 'Are the imperative and interrogative sentences indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'Does “ Is the weather nice today ?” indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'The Republic of Turkey is in the Asian continente” indicate a proposition',
                    answers: ['It is a proposition', 'It is not a proposition'],
                    answerCorect: 'It is a proposition'
                },
                {
                    nameQuestion: 'Does “Is Fatih in this school?” indicate a proposition?',
                    answers: ['Not a proposition', ' It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'Does “Good evening!” indicate a proposition?',
                    answers: ['Not a proposition', ' It is a proposition'],
                    answerCorect: 'Not a proposition'
                }
            ],
        },
        {
            id: '1',
            name: "DEFINITION OF PROPOSAL",
            options: [
                {
                    nameQuestion: 'What do you call the statements that give a true or false final judgement?',
                    answers: ['Proposition', 'Proposal', 'Judgement', 'Experiment'],
                    answerCorect: 'Proposition'
                },
                {
                    nameQuestion: 'Does the question “Turkey&#39;s capital city is Istanbul ” indicate a proposition?',
                    answers: ['Not a proposıtıon ', 'It is a proposition'],
                    answerCorect: 'Not a proposıtıon '
                },
                {
                    nameQuestion: 'Does the question “A week is seven days ” indicate a proposition?',
                    answers: ['It is a proposition', 'It is not a proposition'],
                    answerCorect: 'It is a proposition'
                },
                {
                    nameQuestion: 'Does the question “Where are you going ?” indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'What is a proposition?',
                    answers: ['Statements that state a definite judgement, true or false,are called propositions', 'Statements that make only true judgments are called propositions', 'Proposition only for statements that express a false judgment', ' Statements whose truth is not certain are called propositions'],
                    answerCorect: 'Statements that state a definite judgement, true or false,are called propositions'
                },
                {
                    nameQuestion: 'Are the imperative and interrogative sentences indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'Does “ Is the weather nice today ?” indicate a proposition?',
                    answers: ['Not a proposition', 'It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'The Republic of Turkey is in the Asian continente” indicate a proposition',
                    answers: ['It is a proposition', 'It is not a proposition'],
                    answerCorect: 'It is a proposition'
                },
                {
                    nameQuestion: 'Does “Is Fatih in this school?” indicate a proposition?',
                    answers: ['Not a proposition', ' It is a proposition'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'Does “Good evening!” indicate a proposition?',
                    answers: ['Not a proposition', ' It is a proposition'],
                    answerCorect: 'Not a proposition'
                }
            ]
                   
        },
        {
            id: '2',
            name: "ACCURACY TABLE",
            options: [
                {
                    nameQuestion: 'How many different cases does the truth table of n different propositions consist of?',
                    answers: ['2ⁿ', '3ⁿ', '2n', '3n'],
                    answerCorect: '2ⁿ'
                },
                {
                    nameQuestion: ' What is the truth value of the proposition p:2+3=6?',
                    answers: ['p=0', 'p=1', 'p=3', 'p=4'],
                    answerCorect: 'p=0'
                },
                {
                    nameQuestion: 'How many states does the truth table of four different propositions consist of?',
                    answers: ['2⁴=16', '2⁴=12', '2⁴=18', '2⁴=14'],
                    answerCorect: '2⁴=16'
                },
                {
                    nameQuestion: 'What is the truth value of the proposition r: “32= 9”?',
                    answers: ['r=1', 'r=0', 'r=3', 'r=2'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: ' If the proposition P is false, what is the truth value of the proposition?',
                    answers: ['p=0', 'p=1', 'p=-1', 'p=2'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'If the proposition P is true, what is the truth value of the proposition?',
                    answers: ['p=1', 'p=2', 'p=-1', 'p=0'],
                    answerCorect: 'p=1'
                },
                {
                    nameQuestion: 'Find how many truth states 7 different propositions will have relative to each other.',
                    answers: ['2⁷=128', '2⁷=64', '2⁷=256', '2⁷=512'],
                    answerCorect: '2⁷=128'
                },
                {
                    nameQuestion: 'Since n+2 different propositions have 64 different truth states relative to each other, find the number n.',
                    answers: ['n=4', 'n=1', 'n=2', 'n=3'],
                    answerCorect: 'n=4'
                },
                {
                    nameQuestion: 'What is the truth value of the proposition "6+3> 7 “?',
                    answers: ['1', '2', '3', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'What is the truth value of the proposition "The sum of the interior angles of a triangle is 360 degrees “?',
                    answers: ['0', '2', '3', '1'],
                    answerCorect: '0'
                }
                
            ]
           
        },
        {
            id: '3',
            name: "AND( ^) OR (V) CONNECTOR",
            options: [
                {
                    nameQuestion: '1 ^( 0^1)=?',
                    answers: ['0', 'p', 'q', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: '0V1=?',
                    answers: ['1', '0', '-1', '2'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'P^0=?',
                    answers: ['0', 'p', 'q', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: 'PVP=?',
                    answers: ['p', '0', 'q', '1'],
                    answerCorect: 'p'
                },
                {
                    nameQuestion: '(1 Vq)V (P^0)=?',
                    answers: ['1', 'p', 'q', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: '0^1=?',
                    answers: ['0', '2', '3', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: 'Since ( PVq )^( q^ r )= 1, find the truth value of the propositions p,q,r .',
                    answers: ['1', 'p', 'r', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: '[(1V0 )^ (0^1)]=?',
                    answers: ['0', 'p', 'P`', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: '(0V1) V [(0^1) V (1V0) ]= ?',
                    answers: ['1', '2', '3', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: '(1VP) V (0 VP)= ?',
                    answers: ['1', '3', '0', '2'],
                    answerCorect: '1'
                }
            ]
           
        },
        {
            id: '4',
            name: "OR ( ⊻ ), IF ( ⇒), BUT AND BUT (⇔)",
            options: [
                {
                    nameQuestion: '1 ⊻ 0=?',
                    answers: ['1', 'p', 'r', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'P ⊻ P=?',
                    answers: ['1', 'p', 'P`', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'O ⊻ O=?',
                    answers: ['0', '2', '3', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: '1 ⇔ 0=?',
                    answers: ['0', 'p', 'q', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: '1 ⇔1 =?',
                    answers: ['1', 'p', 'q', '0'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: '0 ⇔ 1=?',
                    answers: ['0', '2', '3', '1'],
                    answerCorect: '0'
                },
                {
                    nameQuestion: 'Find the simplest form of the proposition (1 ⇒ P) ⇒ 0',
                    answers: ['0`', '0', '0', '0'],
                    answerCorect: '0`'
                },
                {
                    nameQuestion: 'Find the simplest form of the proposition (P ⇔ P) ⇔ p',
                    answers: ['p`', 'p', '0', '1'],
                    answerCorect: 'p`'
                },
                {
                    nameQuestion: 'Find the simplest form of the proposition (P ⇒ 1) ⇔ (1 ⇒ q)',
                    answers: ['q`', 'p', 'p`', 'q'],
                    answerCorect: 'q`'
                },
                {
                    nameQuestion: 'Find the truth value of the proposition 1 ⇔ 1',
                    answers: ['0', '2', '3`', '1'],
                    answerCorect: '0'
                }
                    ],
            
        },
        {
            id: '5',
            name: "CONVENTION, INVERSION, AND INVERSION OF CONDITIONAL PROPOSITION",
            options: [
                {
                    nameQuestion: 'What is the negative of the proposition ( P ^ q )?',
                    answers: ['pvq’', 'p', 'q', 'pvq'],
                    answerCorect: 'pvq’'
                },
                {
                    nameQuestion: 'p ⇒ q : What is the opposite of the proposition “If Barış fails, Barış cannot do his homework”?',
                    answers: ['If Baris cannot do homework, it will fail', 'If Baris fails, Peace does homework', 'Peace will be successful if he does not do homework', 'Baris will be successful if it does homework'],
                    answerCorect: 'If Baris cannot do homework, it will fail'
                },
                {
                    nameQuestion: 'What is the opposite of the proposition p ⇒ q ?',
                    answers: ['q⇒p', 'q`⇒p`', 'p⇒ q’', 'q´⇒p'],
                    answerCorect: 'q⇒p'
                },
                {
                    nameQuestion: 'What is the opposite of the proposition p ⇒ q ?',
                    answers: ['q’⇒p’', "q'⇒p'", 'p⇒ q’', 'q´⇒p'],
                    answerCorect: 'q’⇒p’'
                },
                {
                    nameQuestion: 'p:“ The flowers bloomed” q:“ Spring has come” Accordingly, what is the opposite of the proposition p ⇒ q ?  ',
                    answers: ['Flowers do not bloom if spring has not come', 'If the flowers do not bloom, spring will not come', 'Flowers bloom when spring comes', 'Flowers do not bloom if it is spring'],
                    answerCorect: 'Flowers do not bloom if spring has not come'
                },
                {
                    nameQuestion: 'p ⇒ q : “If Sertan is strong, Sertan will do this job” ( p  ⇒ q’ ) = ?',
                    answers: ['If Sertan is not strong, he cannot do this job', ' If Sertan is not strong, he will do this job', 'Sertan is not strong if he does this job', " If Sertan is strong, he can't do it"],
                    answerCorect: 'If Sertan is not strong, he cannot do this job'
                },
                {
                    nameQuestion: 'p:“ Efe did his homework.” What is the opposite of p ⇒ q for the proposition  q: "Efe answered the questions in the exam correctly."',
                    answers: ['If Efe did not do his homework, he will not answer the questions in the exam correctly', ' If Efe did not do his homework, he answers the questions in the exam correctly', 'If Efe has done his homework, he will not answer the questions in the exam correctly', ' Efe did not do his homework if he answered the questions in the exam correctly'],
                    answerCorect: ' If Efe did not do his homework, he will not answer the questions in the exam correctly'
                },
                {
                    nameQuestion: 'What is the opposite of the proposition p ⇒ q ?',
                    answers: ['p’⇒q’', 'p´⇒q', 'q´⇒p´', 'p⇒q’'],
                    answerCorect: 'p’⇒q’'
                },
                {
                    nameQuestion: 'p: “ a is a negative whole number.” q: “ a3 is a negative whole number.” Accordingly, what is the opposite of the proposition p ⇒ q ?',
                    answers: ['If a³ is a negative integer, a is a negative integer', ' If a³ is a negative integer, a is a positive integer', 'a³ is a negative integer if a is a positive integer', 'a3 is a positive integer if a is a negative integer'],
                    answerCorect: 'If a³ is a negative integer, a is a negative integer'
                },
                {
                    nameQuestion: 'Find the simplest form of the proposition (P ⇒ 1) ⇔ (1 ⇒ q)',
                    answers: ['0', '1', 'p∧q', 'p´vq'],
                    answerCorect: '0'
                }
                
                    ],
           
        },
        {
            id: '6',
            name: "CLEAR PROPOSALS AND QUANTITATIVES",
            options: [
                {
                    nameQuestion: 'Find the negative form of the proposition "Every prime number is a natural number" .',
                    answers: ["p': Some prime numbers are not a natural number", 'p´: Some prime numbers are natural numbers', ' p: Not every prime number is a natural number', 'p: Every prime number is a natural number.'],
                    answerCorect: "p': Some prime numbers are not a natural number"
                },
                {
                    nameQuestion: 'p(x): Find the negative form of the proposition  ∃ x ∈ Z, x²+ x > 0 .',
                    answers: ['∀x€ Z,  x²+x≤0', 'Ǝx€ Z, x²+x<0', '∀x€ Z, x²+x≥0', 'Ǝx€ Z, x²+x≤0'],
                    answerCorect: '∀x€ Z,  x²+x≤0'
                },
                {
                    nameQuestion: 'q(x): Find the negative form of the proposition  ∀ x . y ∈ N, x .y=6” ',
                    answers: ['Ǝx,y € N, x.y≠6', '∀x,y€ N,x.y≠6', '∀x,y€ N, x.y=6', 'Ǝx,y € N, x.y=6'],
                    answerCorect: ' Ǝx,y € N, x.y≠6'
                },
                {
                    nameQuestion: 's(x): Find the negative form of the proposition  ∀ x ∈ Z ⁺ , 2 x-1 ≤10 ',
                    answers: ['Ǝx€ Z⁺,2x-1>10', '∀x€ Z⁺,2x-1≤10', '∀x€ Z⁺,2x-1>10', 'Ǝx€ Z⁺,2x-1≥10'],
                    answerCorect: 'Ǝx€ Z⁺,2x-1>10'
                },
                {
                    nameQuestion: 'State the expression  ∀ x ∈ Z ⁺ x² >0 given using symbolic logic verbally. ',
                    answers: ['Not a propositionThe square of every positive integer is greater than zero', 'The square of every positive integer is less than zero', 'The square of some positive integers is greater than zero', 'The square of some positive integers is less than zero'],
                    answerCorect: 'The square of every positive integer is greater than zero'
                },
                {
                    nameQuestion: 'State the expression  ∃ x ∈ Z, x-2 ≤8 given using symbolic logic verbally. ',
                    answers: ['At least one integer less than 2 is equal to or less than 8', 'At least one integer less than 2 is equal to 8', ' Every integer minus 2 is less than or equal to 8', ' Every integer minus 2 is grater or equal to 8'],
                    answerCorect: 'At least one integer less than 2 is equal to or less than 8'
                },
                {
                    nameQuestion: 'p: Write the proposition "Every integer is less than its square" using symbolic logic.',
                    answers: ['∀x€ Z, x<x²', 'Ǝx€ Z, x≤ x²', '∀x€ Z, x≤ x²', 'Ǝx€ Z , x< x²'],
                    answerCorect: '∀x€ Z, x<x²'
                },
                {
                    nameQuestion: 'State the proposition  “ ∃ x ∈ Z,  x+2 > 5verbally.',
                    answers: ['At least one integer is greater than 2 plus 5', 'At least one integer is less than 2 plus 5 ', 'Every integer is greater than 2 plus 5', 'Every integer is less than 2 plus 5'],
                    answerCorect: 'At least one integer is greater than 2 plus 5'
                },
                {
                    nameQuestion: 'p(x): x ∈ N, 4 < x ² <17 how many elements does the truth set of open propositions have?',
                    answers: ['2', '7', '10', '12'],
                    answerCorect: '2'
                },
                {
                    nameQuestion: '( ∀ x ∈ R, x² > x) V ( ∀ x ∈ R, x=2) Write the negative form of the proposition.',
                    answers: ['(Ǝx€ R, x²≤x) ∧ (Ǝx€ R, x≠2)', '( ∀x€R, x²≥x) ∧ (∀x€R,x≠2)', '(Ǝx€R, x²<x) ∧ (Ǝx€R,x≠2)', '(∀x€R, x²<x) v ( ∀x€R,x≠2)'],
                    answerCorect: '(Ǝx€ R, x²≤x) ∧ (Ǝx€ R, x≠2)'
                }
                
                    ],
            
        },
        {
            id: '7',
            name: "PROOF METHODS",
            options: [
                {
                    nameQuestion: 'What are the statements that are accepted as true without proof called ?',
                    answers: ['Axiom', 'Hypothesis', 'Judgement', 'Theorem'],
                    answerCorect: 'Axiom'
                },
                {
                    nameQuestion: 'p: State whether the proposition "All right angles are equal to each other" is an axiom or not.',
                    answers: ['it is an axiom', 'it is not an axiom'],
                    answerCorect: 'it is an axiom'
                },
                {
                    nameQuestion: 'The proposition p, where p ⇒q  is the theorem ……. ., to proposition q ……. called . Fill in the dotted spaces in the statement.',
                    answers: ['Hypothesis, Judgement', 'Judgement, Theorem', 'Axiom, Judgement', 'Theorem, Axiom'],
                    answerCorect: 'Hypothesis, Judgement'
                },
                {
                    nameQuestion: 'What are the propositions that need to be proven true ?',
                    answers: ['Theorem', 'Hypothesis', 'Judgement', 'Axiom'],
                    answerCorect: 'Theorem'
                },
                {
                    nameQuestion: 'What is the name given to showing that the truth value of the hypothesis of a theorem is 1 while the truth value of its judgement is 1 ?',
                    answers: ['Proof', 'Hypothesis', 'Judgement ', 'Axiom'],
                    answerCorect: 'Proof'
                },
                {
                    nameQuestion: 'Express the theorem (x+2 ≠ 0) ⇒ (10x-3 ≠-23) with the "opposite inverse method" ?',
                    answers: ['(10x-3= -23) ⇒ (x+2=0)', '(10x-3≠ -23) ⇒ (x+2≠0)', '(10x-3= -23) ⇒ (x+2≠0)', '(x+2=0) ⇒ (10x-3= -23)'],
                    answerCorect: '(10x-3= -23) ⇒ (x+2=0)'
                },
                {
                    nameQuestion: 'By which method is the proof made using the equivalence p ⇒ q ≡ q ′ ⇒ p ′ expressed ?',
                    answers: ['Proof with the contrary', 'Proof by contradiction', 'Proof by inverse', 'Proof by negation'],
                    answerCorect: 'Proof with the contrary'
                },
                {
                    nameQuestion: 'What is the name given to p in the p ⇒ q theorem ?',
                    answers: ['Hypothesis', 'Judgement', 'Theorem', 'Axiom'],
                    answerCorect: 'Hypothesis'
                },
                {
                    nameQuestion: 'What is the name given to q in the p ⇒ q theorem ?',
                    answers: ['Judgement', 'Axiom', 'Proof', 'Theorem'],
                    answerCorect: 'Judgement'
                },
                {
                    nameQuestion: 'State the hypothesis of the theorem in the statement "If triangle ABC is equilateral, then all interior angle measures of triangle ABC are equal to each other."',
                    answers: ['triangle “ABC” is equilateral triangle', 'All interior angle measures of ABC triangle are equal to each other', 'ABC triangle is not an equilateral triangle', 'ABC triangle is an isosceles triangle.'],
                    answerCorect: 'triangle “ABC” is equilateral triangle'
                }
                
                    ],
           
        },
    ],
    [
        {
            id: '0',
            name: "BONUS",
            options: [
                {
                    nameQuestion: 'Find the negative form of the proposition "Every prime number is a natural number" .',
                    answers: ['1', '2', '3', '4'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'p(x): Find the negative form of the proposition  ∃ x ∈ Z, x²+ x > 0 .',
                    answers: ['1', '2', '3', '4'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'q(x): Find the negative form of the proposition  ∀ x . y ∈ N, x .y=6” ',
                    answers: ['1', '2', '3', '4'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 's(x): Find the negative form of the proposition  ∀ x ∈ Z ⁺ , 2 x-1 ≤10 ',
                    answers: ['1', '2', '3', '4'],
                    answerCorect: '1'
                },
                {
                    nameQuestion: 'State the expression  ∀ x ∈ Z ⁺ x² >0 given using symbolic logic verbally. ',
                    answers: ['Not a proposition', '2', '3', '4'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'State the expression  ∃ x ∈ Z, x-2 ≤8 given using symbolic logic verbally. ',
                    answers: ['Statements that state a definite judgement, true or false,are called propositions', '2', '3', '4'],
                    answerCorect: 'Statements that state a definite judgement, true or false,are called propositions'
                },
                {
                    nameQuestion: 'p: Write the proposition "Every integer is less than its square" using symbolic logic.',
                    answers: ['Not a proposition', '2', '3', '4'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'State the proposition  “ ∃ x ∈ Z,  x+2 > 5verbally.',
                    answers: ['Not a proposition', '2', '3', '4'],
                    answerCorect: 'Not a proposition'
                },
                {
                    nameQuestion: 'p(x): x ∈ N, 4 < x ² <17 how many elements does the truth set of open propositions have?',
                    answers: ['A proposition', '2', '3', '4'],
                    answerCorect: 'A proposition'
                },
                {
                    nameQuestion: '( ∀ x ∈ R, x² > x) V ( ∀ x ∈ R, x=2) Write the negative form of the proposition.',
                    answers: ['Not a proposition', '2', '3', '4'],
                    answerCorect: 'Not a proposition'
                }
                
                    ],
        },
        {
            id: '1',
            name: "DESCRIPTION AND DESCRIPTION OF THE SET",
            options: [
                {
                    nameQuestion: 'What is each of the objects that make up the set called?',
                    answers: ['The elements of the set', '2', '3', '4'],
                    answerCorect: 'The elements of the set'
                },
                {
                    nameQuestion: 'Does "smart girls in our school" indicate a set ?',
                    answers: ['Does not specıfy a set', '2', '3', '4'],
                    answerCorect: 'Does not specıfy a set'
                },
                {
                    nameQuestion: 'Do the days of the week starting with the letter "Ç" indicate a set?',
                    answers: ['Indıcates set A=(Wednesday)', '2', '3', '4'],
                    answerCorect: 'Indıcates set A=(Wednesday)'
                },
                 {
                    nameQuestion: 'If A ={ 1,2{3,4},5} then s(A)=?',
                    answers: ['s(A)=4', '2', '3', '4'],
                    answerCorect: 's(A)=4'
                },
                {
                    nameQuestion: 'Is {3,4}EA for the set A ={{ 3,4}5,6,7{8}} ?',
                    answers: ['{3,4}€A', '2', '3', '4'],
                    answerCorect: '{3,4}€A'
                },
                {
                    nameQuestion: 'Write the set K given by K ={ x|5<x<14,x odd natural number} common property method in list format.',
                    answers: ['k={ 7,9,11,13 }', '2', '3', '4'],
                    answerCorect: 'k={ 7,9,11,13 }'
                },
                {
                    nameQuestion: 'Show the letters of the word “ADANALILAR” using the list method.',
                    answers: ['B={A,D,N,L,I,R}', '2', '3', '4'],
                    answerCorect: 'B={A,D,N,L,I,R}'
                },
                {
                    nameQuestion: 'Show the set of prime numbers using the list method.',
                    answers: ['A={2,3,5,7}', '2', '3', '4'],
                    answerCorect: 'A={2,3,5,7}'
                },
                {
                    nameQuestion: 'Does the sentence “some districts of Ankara” indicate a set?',
                    answers: ['Does not specıfy a set', '2', '3', '4'],
                    answerCorect: 'Does not specıfy a set'
                },
                {
                    nameQuestion: 'If A={ a, b ,c { d, e }} then s(A)=?',
                    answers: ['s(A)=4', '2', '3', '4'],
                    answerCorect: 's(A)=4'
                }               
                
                    ],
        },
        {
            id: '2',
            name: "FINITE, INFINITE , UNIVERSAL, EMPTY SETS",
            options: [
                {
                    nameQuestion: 'Is C ={ …1,2,3...} a finite set ? Is it an infinite set?',
                    answers: ['infinite set', '2', '3', '4'],
                    answerCorect: 'infinite set'
                },
                {
                    nameQuestion: 'What kind of sets come to mind when we say finite and infinite sets?',
                    answers: ['İf the elements of a set are countable,this set ıs called a finite set,and non-fınıte sets are called an ınfınıte set .', '2', '3', '4'],
                    answerCorect: 'İf the elements of a set are countable,this set ıs called a finite set,and non-fınıte sets are called an ınfınıte set .'
                },
                {
                    nameQuestion: 'Does " rows in our class" indicate an infinite set?',
                    answers: ['Is fınıte', '2', '3', '4'],
                    answerCorect: 'Is fınıte'
                },
                 {
                    nameQuestion: 'What is a finite set? Give an example.',
                    answers: ['A set is infinite set if its eiements are countable. A= {1,3,5,7,9} s(A)=5 A set is finite', '2', '3', '4'],
                    answerCorect: 'A set is infinite set if its eiements are countable. A= {1,3,5,7,9} s(A)=5 A set is finite'
                },
                {
                    nameQuestion: 'What do you call a set whose number of elements cannot be expressed with natural numbers?',
                    answers: ['infinite set', '2', '3', '4'],
                    answerCorect: 'infinite set'
                },
                {
                    nameQuestion: 'Show consecutive integers that are multiples of 3 using the list method.',
                    answers: ['A={…-6,-3,0,3,6,…}', '2', '3', '4'],
                    answerCorect: 'A={…-6,-3,0,3,6,…}'
                },
                {
                    nameQuestion: 'Show the set A ={ 1,3,5,7,9} and the universal set E={1,2,3,4,5,6,7,8,9} with venn diagram.',
                    answers: ['cuadro agregar', '2', '3', '4'],
                    answerCorect: 'cuadro agregar'
                },
                {
                    nameQuestion: 'Does the set A ={ A|-3<a<2,a a prime number} indicate an empty set?',
                    answers: ['A={ } Empty set', '2', '3', '4'],
                    answerCorect: 'A={ } Empty set'
                },
                {
                    nameQuestion: 'Does C ={ 0} specify an empty set?',
                    answers: ['The empty set is a set with one element that does not specıfy', '2', '3', '4'],
                    answerCorect: 'The empty set is a set with one element that does not specıfy'
                },
                {
                    nameQuestion: 'Does “some weak students in our school” refer to a set?',
                    answers: ['does not specıfy a set', '2', '3', '4'],
                    answerCorect: 'does not specıfy a set'
                }
            ],
        },
        {
            id: '3',
            name: "SUBSET",
            options: [
                {
                    nameQuestion: 'When the number of elements is increased by 2, what is the number of elements of a set with 32 subsets?',
                    answers: ['2 ⁿ ⁺ ² = 32  ->   2 ⁿ ⁺  ² =2 ⁵    n+2=5   n=3', '2', '3', '4'],
                    answerCorect: '2 ⁿ ⁺ ² = 32  ->   2 ⁿ ⁺  ² =2 ⁵    n+2=5   n=3'
                },
                {
                    nameQuestion: 'What is the number of elements of a set whose ratio of the number of subsets to the number of elements is 32/3?',
                    answers: ['2 ⁿ/n = 32/3 3.2 ⁿ=2 ⁵ .n -> 2 ⁿ= 2 ⁵.n /3 -> 26= 2 ⁵.6 /3 -> n= 6', '2', '3', '4'],
                    answerCorect: '2 ⁿ/n = 32/3 3.2 ⁿ=2 ⁵ .n -> 2 ⁿ= 2 ⁵.n /3 -> 26= 2 ⁵.6 /3 -> n= 6'
                },
                {
                    nameQuestion: 'Since A={ a, e ,o, u, ü } B={ e, a, o } ACB, show the given elements in the venn diagram.',
                    answers: ['agregar imagen', '2', '3', '4'],
                    answerCorect: 'agregar imagen'
                },
                 {
                    nameQuestion: 'Find the number of subsets and the number of subsets of a set with 6 elements.',
                    answers: ['26 = 64 subset 64-1= 63 self subset', '2', '3', '4'],
                    answerCorect: '26 = 64 subset 64-1= 63 self subset'
                },
                {
                    nameQuestion: 'What is the number of subsets of A={ a, b { c, d, e }}?',
                    answers: ['23 =8', '2', '3', '4'],
                    answerCorect: '23 =8'
                },
                {
                    nameQuestion: 'What is the number of subsets of A= {1,2,3}?',
                    answers: ['23 = 8', '2', '3', '4'],
                    answerCorect: '23 = 8'
                },
                {
                    nameQuestion: 'How many of the subsets of the set A={ a, b ,c, d } do not have a element.',
                    answers: ['23= 8 a is not found', '2', '3', '4'],
                    answerCorect: '23= 8 a is not found'
                },
                {
                    nameQuestion: 'How many of the subsets of the set A={ a, b ,c, d } contain a element.',
                    answers: ['23= 8 is found', '2', '3', '4'],
                    answerCorect: '23= 8 is found'
                },
                {
                    nameQuestion: 'What is the number of subsets of a set with 5 elements?',
                    answers: ['25=32', '2', '3', '4'],
                    answerCorect: '25=32'
                },
                {
                    nameQuestion: 'Find the core subset number of the set A formed by using the letters in the word “KARAMAN”.',
                    answers: ['{K,A,R,M,N} self subset 2n – 1= 25 -1= 32-1=31', '2', '3', '4'],
                    answerCorect: '{K,A,R,M,N} self subset 2n – 1= 25 -1= 32-1=31'
                }
            ],
        },
        {
            id: '4',
            name: "INTERSECTION AND COMBINATION OF SETS",
            options: [
                {
                    nameQuestion: 'A={a, b, c, d, e,}    B= { d, e, f}   write the elements of AUB  set.',
                    answers: ['A∪B={a,b,c,d,e,f}', '2', '3', '4'],
                    answerCorect: 'A∪B={a,b,c,d,e,f}'
                },
                {
                    nameQuestion: 'Since A={ a, b {b}, c, d } B={{b}, c, a { b, d }}, how many elements does A ^ B have?',
                    answers: ['A∩B={a,{b},c} s(A∩B)=3', '2', '3', '4'],
                    answerCorect: 'A∩B={a,{b},c} s(A∩B)=3'
                },
                {
                    nameQuestion: 'AUB={ a,b ,c,d,e }, AUB={ a,e,ı,i }   AU(B ^C)=?',
                    answers: ['A∪(B∩C)=(A∪B) ∩(A∪C) ={a,e}', '2', '3', '4'],
                    answerCorect: 'A∪(B∩C)=(A∪B) ∩(A∪C) ={a,e}'
                },
                 {
                    nameQuestion: 'If A={ a,b ,c,d } and B={ c,d,e } show the sets A ^ B and AUB with list method.',
                    answers: ['A∩B={c,d} A∪B={a,b,c,d,e}', '2', '3', '4'],
                    answerCorect: 'A∩B={c,d} A∪B={a,b,c,d,e}'
                },
                {
                    nameQuestion: 'Since A ={ x/x is an even number} B={x/x is an even number, find the set A ^ B.',
                    answers: ['A∩B={0,2,4,6,8}', '2', '3', '4'],
                    answerCorect: 'A∩B={0,2,4,6,8}'
                },
                {
                    nameQuestion: 'The sets A={ 2,x ,3,4} and B={1,2,3,a} are given . Write the set A ^ B and show it with venn diagram.',
                    answers: ['imagen', '2', '3', '4'],
                    answerCorect: 'imagen'
                },
                {
                    nameQuestion: 'The sets A ={ 1,2,4} and B={2,4} are given. Write the set A ^ B and show it with the list method.',
                    answers: ['A∩B={2,4}', '2', '3', '4'],
                    answerCorect: ' A∩B={2,4}'
                },
                {
                    nameQuestion: 'In the adjacent diagram, the numbers indicate the number of elements belonging to the region where they are located.',
                    answers: ['a)s(A∪B)=s(A)+s(B)-s(A∩B)=7 +5-2 =12-2 =2 =10', '2', '3', '4'],
                    answerCorect: 'a)s(A∪B)=s(A)+s(B)-s(A∩B)=7 +5-2 =12-2 =2 =10'
                },
                {
                    nameQuestion: 'Cambios Pendientes 9) Intersection and combination of sets',
                    answers: ['imagen', '2', '3', '4'],
                    answerCorect: 'imagen'
                },
                {
                    nameQuestion: 'A, B and C,E are subsets of the universal set. Since A ^ B ={ 1,2}  A ^ C=Ø,  find the set A^(BUC).',
                    answers: ['A∩(B∪C)=(A∩B) ∪ (A∩C) ={1,2}∪∅ ={1,2}', '2', '3', '4'],
                    answerCorect: 'A∩(B∪C)=(A∩B) ∪ (A∩C) ={1,2}∪∅ ={1,2}'
                }
            ],
        },
        {
            id: '5',
            name: "COMPLEMENTARY OF THE SET",
            answer: "",
            options: [
                {
                    nameQuestion: 'In a set A (A ′ ) ′ = ?',
                    answers: ['A', '2', '3', '4'],
                    answerCorect: 'A'
                },
                {
                    nameQuestion: 'A For the set  AUA ′ = ?',
                    answers: ['A∪A’=E', '2', '3', '4'],
                    answerCorect: 'A∪A’=E'
                },
                {
                    nameQuestion: 'İn the set (A)  A- A ′ = ?',
                    answers: ['A-A’=A', '2', '3', '4'],
                    answerCorect: 'A-A’=A'
                },
                 {
                    nameQuestion: 'universal set included (E) E ′ = ?',
                    answers: ['E’={}', '2', '3', '4'],
                    answerCorect: 'E’={}'
                },
                {
                    nameQuestion: 'Cambios Pendientes 5) Complementary of the set',
                    answers: ['A-B={1,2}', '2', '3', '4'],
                    answerCorect: 'A-B={1,2}'
                },
                {
                    nameQuestion: 'Cambios Pendientes 6) Complementary of the set',
                    answers: ['A∩B’={1,2,5,6,7}', '2', '3', '4'],
                    answerCorect: 'A∩B’={1,2,5,6,7}'
                },
                {
                    nameQuestion: 'A and B are subsets of the universal set E. s (A) + s(B ′ ) = 13-3a and s(A ′ ) + s(B) = 15+3a, find the value of s(E).',
                    answers: ['s(E)+s(E)=13-3a+15+3a=28   -> 2s(E)=28   ->  S(E)=14', '2', '3', '4'],
                    answerCorect: 's(E)+s(E)=13-3a+15+3a=28   -> 2s(E)=28   ->  S(E)=14'
                },
                {
                    nameQuestion: 'If  E = { 1 ,2,3,4,5,6} , A= {3,4,5} write the elements of A ′. ',
                    answers: ['A’={1,2,6}', '2', '3', '4'],
                    answerCorect: 'A’={1,2,6}'
                },
                {
                    nameQuestion: 'including a set (A)  A ∩ A′ = ? ',
                    answers: ['A∩A’={}', '2', '3', '4'],
                    answerCorect: 'A∩A’={}'
                },
                {
                    nameQuestion: 'If E is a universal set find E – A= ?',
                    answers: ['.E-A=A’', '2', '3', '4'],
                    answerCorect: '.E-A=A’'
                }
            ],
        },
        {
            id: '6',
            name: "DIFFERENCE OPERATION IN SETS",
            options: [
                {
                    nameQuestion: 'Find A- B for the sets A= { p,r ,s,ş,t } and B= { r,ş,m,n }.',
                    answers: ['A-B={p,s,t}', '2', '3', '4'],
                    answerCorect: 'A-B={p,s,t}'
                },
                {
                    nameQuestion: ' Find the set B- A for the sets A= { p,r ,s,ş,t } and B= { r,ş,m,n }.',
                    answers: ['B-A={m,n}', '2', '3', '4'],
                    answerCorect: 'B-A={m,n}'
                },
                {
                    nameQuestion: 'E being the universal set ; E= {1,2,3,4,5,6,7 } , A= {1,2,3,4} , B= {3,4,5] sets are given. Represent the given sets with a venn diagram.',
                    answers: ['imagen', '2', '3', '4'],
                    answerCorect: 'imagen'
                },
                 {
                    nameQuestion: 'E being the universal set ; E= {1,2,3,4,5,6,7 } , A= {1,2,3,4} , B= {3,4,5] sets are given. Find the set E - ( AUB).',
                    answers: ['E-( A∪B)={6,7}', '2', '3', '4'],
                    answerCorect: 'E-( A∪B)={6,7}'
                },
                {
                    nameQuestion: 'If A ⊂  B, show the set B- A with a venn diagram.',
                    answers: ['imagen', '2', '3', '4'],
                    answerCorect: 'imagen'
                },
                {
                    nameQuestion: 'Since A= {1,2,3,4} B ={ 3,4,5,6}, write the set (AUB) - (A ∩ B ).',
                    answers: ['(A∪B)-(A∩B)={1,2,3,4,5,6 }-{3,4}={1,2,5,6}', '2', '3', '4'],
                    answerCorect: '(A∪B)-(A∩B)={1,2,3,4,5,6 }-{3,4}={1,2,5,6}'
                },
                {
                    nameQuestion: 'If E= {1,2,3,4,5}, A= {1,2,3}, B= {3,4}, find the elements of E - ( AUB).',
                    answers: ['E-(A∪B)={1,2,3,4,5}-{1,2,3,4}={5}', '2', '3', '4'],
                    answerCorect: 'E-(A∪B)={1,2,3,4,5}-{1,2,3,4}={5}'
                },
                {
                    nameQuestion: 'If A= {1,2,3,4,5 } , B= {2,3,4}, write the elements of AB.',
                    answers: ['A-B={1,5}', '2', '3', '4'],
                    answerCorect: 'A-B={1,5}'
                },
                {
                    nameQuestion: 'Including the universal set (E) find A- E = ?',
                    answers: ['A-E={ }', '2', '3', '4'],
                    answerCorect: 'A-E={ }'
                },
                {
                    nameQuestion: 'E being the universal set, find A- A’ = ?',
                    answers: ['A-A’=A', '2', '3', '4'],
                    answerCorect: 'A-A’=A'
                }
            ],
        },
        {
            id: '7',
            name: "CARTESIAN PRODUCT",
            options: [
                {
                    nameQuestion: 'Find the numbers x and y that satisfy the equation (2x-4, 3y+6) = (-10, 18).',
                    answers: ['2X-4=-10 -> 2X=-10+4 -> 2X=-6 X=-3 // 3y+6= 18 -> 3y=18-6 -> 3y=12 y=4', '2', '3', '4'],
                    answerCorect: '2X-4=-10 -> 2X=-10+4 -> 2X=-6 X=-3 // 3y+6= 18 -> 3y=18-6 -> 3y=12 y=4'
                },
                {
                    nameQuestion: 'If A= { a,b ,c } , B= {7,9} write the set A x B.',
                    answers: ['AXB={(a,7),(a,9),(b,7),(b,9),(C,7),(c,9)}', '2', '3', '4'],
                    answerCorect: 'AXB={(a,7),(a,9),(b,7),(b,9),(C,7),(c,9)}'
                },
                {
                    nameQuestion: 'If A= {1,3,5,7,9 } , B= { k,l,m,n }, find the number of elements of the set A x B.',
                    answers: ['s(AXB)=5.4=20', '2', '3', '4'],
                    answerCorect: 's(AXB)=5.4=20'
                },
                 {
                    nameQuestion: 'A= {1,2,3,4,5,6} set is given. Find the number of elements of the set A x A',
                    answers: ['s(AXA)=6.6=36', '2', '3', '4'],
                    answerCorect: 's(AXA)=6.6=36'
                },
                {
                    nameQuestion: 'If A= {2,3,4} B ={ 3,5}, write the set A x B.',
                    answers: ['AXB={(2,3),(2,5),(3,3),(3,5),(4,3),(4,5)}', '2', '3', '4'],
                    answerCorect: 'AXB={(2,3),(2,5),(3,3),(3,5),(4,3),(4,5)}'
                },
                {
                    nameQuestion: 'If (x²-y ²,x +y) = (5.5) then x .y= ?',
                    answers: ['x2 – y2=5 -> (x+y).(x-y)=5 -> 5.(x-y)=5 -> (xy)=1', '2', '3', '4'],
                    answerCorect: 'x2 – y2=5 -> (x+y).(x-y)=5 -> 5.(x-y)=5 -> (xy)=1'
                },
                {
                    nameQuestion: 'Since (a+2 ,4) = (5, b-3), a .b ?',
                    answers: ['a+2=5 b-3=4 a*b=3*7=21 -> a=3 b=7', '2', '3', '4'],
                    answerCorect: 'a+2=5 b-3=4 a*b=3*7=21 -> a=3 b=7'
                },
                {
                    nameQuestion: 'A x B = { ( 1,a), (1,b), (1,c), (2,a), (2,b), (2,c) } write.',
                    answers: ['A={1,2} B={a,b,c}', '2', '3', '4'],
                    answerCorect: 'A={1,2} B={a,b,c}'
                },
                {
                    nameQuestion: 'If A= {0,1,2 } , B= {2,3,4,5}, write the number of elements of A x B.',
                    answers: ['s(AXB)=3.4=12', '2', '3', '4'],
                    answerCorect: 's(AXB)=3.4=12'
                },
                {
                    nameQuestion: 'If A= { b,c ,d } , B={1,2}, write the elements of B x A.',
                    answers: ['.BXA={(1,b),(1,c),(1,d),(2,b),(2,c),(2,d)}', '2', '3', '4'],
                    answerCorect: '.BXA={(1,b),(1,c),(1,d),(2,b),(2,c),(2,d)}'
                }
            ],
        },
    ]
];

export default questions;
<template>
    <div class="col-auto text-center">
        <div class="row w-100">  

            <!--WELCOME MAIN-->
            <div class="col-12">
                <h1 class="title-welcome">Welcome</h1>
                <div class="d-flex w-100 justify-content-center">
                    <div class="elipses e-purple" @click="selectColor('purple')">
                        <img :src="piezaPurple">
                    </div>
                    <div class="elipses e-blue" @click="selectColor('blue')">
                        <img :src="piezaBlue">
                    </div>
                    <div class="elipses e-red" @click="selectColor('red')">
                        <img :src="piezaRed">
                    </div>
                    <div class="elipses e-green" @click="selectColor('green')">
                        <img :src="piezaGreen">
                        
                    </div>
                </div>
                <div class="row mt-5">

                    <div class="col-12">
                        <h3 class="title-chose-colors">Chose your color</h3>
                    </div>

                </div>


                
            </div>



            <!--BUTTONS-->
            <div class="d-flex justify-content-center mt-3" style="margin-bottom: 20px;">
                    <div style="display:none">
                        <button class="btn btn-primary"> Instrucciones </button>
                    </div>
                    <div>
                        <button class="btn btn-primary" :disabled="teams.team1.name == '' || teams.team2.name == '' " @click="startGame"> Play </button>
                    </div>
            </div>

            <config-pm @changeVolumen="changeVolumen" :teams="teams" />
            
        </div>



        <!-- Modal Name -->
        <div class="modal fade" id="setNameModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-body">
                <div class="form-group">
                    <label for="selectName" class="player-name">
                        Please chose your name: 
                    </label>
                    <div class="form-group d-flex justify-content-center mb-2">
                        <div class="elipses e-purple"
                            v-if="selectedColor == 'purple' "
                            style=" height: 130px;
                                    width: 130px;">
                            <img :src="piezaPurple">
                        </div>

                        <div class="elipses e-blue" 
                            v-else-if="selectedColor == 'blue' "
                            style=" height: 130px;
                                    width: 130px;">
                            <img :src="piezaBlue">
                        </div>

                        <div class="elipses e-green"
                            v-else-if="selectedColor == 'green' "
                            style=" height: 130px;
                                    width: 130px;">
                            <img :src="piezaGreen">
                        </div>
                        
                        <div class="elipses e-red"
                            v-else-if="selectedColor == 'red' "
                            style=" height: 130px;
                                    width: 130px;">
                            <img :src="piezaRed">
                        </div>
                    </div>
                    <input type="text" v-model="selectedName" class="form-control" maxlength="8">
                </div>

            </div>
            <div class="modal-footer" style="justify-content:center!important;;">
                <button type="button" class="btn btn-primary" @click="saveName"
                style="font-size: 35px!important;
                        height: 50px!important;
                        width: 217px!important;
                        line-height: 34px!important;"
                >Save</button>
            </div>
            </div>
        </div>
        </div>

    </div>
</template>
<script>
//IMG
import piezaPurple from "@/assets/img/item-purple.png";
import piezaBlue from "@/assets/img/item-blue.png";
import piezaRed from "@/assets/img/item-red.png";
import piezaGreen from "@/assets/img/item-green.png";


import ConfigPm from '@/components/Config.vue';

export default {
    props: ['teams'],
    data: () => ({
        selectedName: '',
        selectedColor: '',
        piezaPurple,
        piezaBlue,
        piezaRed,
        piezaGreen
    }),
    methods: {
        changeVolumen(nuevoVolumen) {
            this.$emit("changeVolumen", nuevoVolumen);
        },  
        selectColor(color) {
            this.selectedColor = color;
            if(!this.teams.team1.name) {
                this.teams.team1.color = color;
            } else {
                this.teams.team2.color = color;
            }

            $("#setNameModal").modal('show');
        },
        saveName() {
            if(!this.teams.team1.name) {
                this.teams.team1.name = this.selectedName;
            } else {
                this.teams.team2.name = this.selectedName;
            }
            this.selectedName = '';
            $("#setNameModal").modal('hide');
        },
        startGame() {
            this.teams.status = true;
        }
    },
    components: {ConfigPm}
}
</script>
<style lang="scss">
    .elipses {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /*Ellipse*/
        width: 205px;
        margin: 5px;
        height: 200px;
        border-radius: 50%;
        background: #C39BD3;    
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        img {
            height: 98%;
            margin-top: 30px;
        }

    }
    /*Colors*/
    .e-purple {
        background: #C39BD3;
    }
    .e-blue {
        background: #7FB3D5;
    }
    .e-red {
        background: #D98880; 
    }
    .e-green {
        background:#7DCEA0;
    }

    .player-name {
        width: 100%;
        height: 83px;
        font-family: 'Inika';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 83px;

        color: #121B08;
    }
</style>
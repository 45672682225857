<template>
<div class="w-100">
        <div class="d-flex justify-content-center aling-content-center">
            <div class="tablero">
            <div class="b" id="position1"><div>Go ➟</div></div>
            <div class="a" id="position2"></div>
            <div class="a" id="position3"></div>
            <div class="a" id="position4"></div>
            <div class="a" id="position5"></div>
            <div class="a" id="position6"></div>
            <div class="a" id="position7"></div>
            <div class="a" id="position8"></div>
            <div class="c" id="position28"><div>Up ⭷</div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div class="a" id="position9"></div>
            <div class="a" id="position27"></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div class="a" id="position10"></div>
            <div class="a" id="position26"></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div class="a" id="position11"></div>
            <div class="a" id="position25"></div>
            <div ></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div class="a" id="position12"></div>
            <div class="a" id="position24"></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div></div>
            <div  class="a" id="position13"></div>
            <div  class="a" id="position23"></div>
            <div></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div></div>
            <div ></div>
            <div  class="a" id="position14"></div>
            <div  class="a" id="position22"></div>
            <div  class="a" id="position21"></div>
            <div class="a" id="position20"></div>
            <div class="a" id="position19"></div>
            <div class="a" id="position18"></div>
            <div class="a" id="position17"></div>
            <div class="a" id="position16"></div>
            <div class="a" id="position15"></div>
        </div>

        <div class="tablero2">
                <div class="b" id="position29">Go ➟</div>
                <div class="a" id="position30"></div>
                <div class="a" id="position31"></div>
                <div class="a" id="position32"></div>
                <div class="a" id="position33"></div>
                <div class="a" id="position34"></div>
                <div class="c" id="position48">Up ⭷</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div class="a" id="position35"></div>
                <div class="a" id="position47"></div>        
                <div></div>        
                <div></div>
                <div></div>
                <div></div>
                <div class="a" id="position36"></div>        
                <div class="a" id="position46"></div>       
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div class="a" id="position37"></div>        
                <div class="a" id="position45"></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div class="a" id="position38"></div>        
                <div class="a" id="position44"></div>
                <div class="a" id="position43"></div>
                <div class="a" id="position42"></div>
                <div class="a" id="position41"></div>
                <div class="a" id="position40"></div>
                <div class="a" id="position39"></div>               
            </div>

            <div class="tablero3">
                <div class="b" id="position49">Go ➟</div>
                <div class="a" id="position50"></div>
                <div class="a" id="position51"></div>
                <div class="a" id="position52"></div>
                <div class="c" id="position60">Win ⭐</div>           
                <div @click="goBack" style="cursor: pointer;"> </div>      
                <div></div>    
                <div class="a" id="position53"></div>       
                <div class="a" id="position59"></div>
                <div></div>
                <div></div>
                <div class="a" id="position54"></div>        
                <div class="a" id="position58"></div>       
                <div class="a" id="position57"></div>
                <div class="a" id="position56"></div>
                <div class="a" id="position55"></div>
                
                    
            </div>
        </div>
        <div class="d-flex justify-content-end mt-5">
            <button type="button" class="btn btn-primary"
            @click="goBack"
                style="font-size: 35px!important;
                        height: 50px!important;
                        width: 217px!important;
                        line-height: 34px!important;z-index:300"
                >Go Back</button>
        </div>
    </div>
</template>

<script>
//IMG
import piezaPurple from "@/assets/img/item-purple.png";
import piezaBlue from "@/assets/img/item-blue.png";
import piezaRed from "@/assets/img/item-red.png";
import piezaGreen from "@/assets/img/item-green.png";

export default {
    props: ['teams'],
    data: () => ({

    }),
    methods: {
        goBack() {
            this.teams.boardView = false;
        }
    },
    mounted() {
        const position1 = this.teams.team1.position;
        const position2 = this.teams.team2.position;
        let img1 = '';
        let img2 = '';

        if (this.teams.team1.color == 'purple') {
            img1 =  `<div class="elipses e-purple" style="height:35px;width:35px"><img src="${piezaPurple}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team1.color == 'blue') {
            img1 =  `<div class="elipses e-blue" style="height:35px;width:35px"><img src="${piezaBlue}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team1.color == 'green') {
            img1 =  `<div class="elipses e-green" style="height:35px;width:35px"><img src="${piezaGreen}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team1.color == 'red') {
            img1 =  `<div class="elipses e-red" style="height:35px;width:35px"><img src="${piezaRed}" style="height:35px;margin-top:10px!important"></div>`;
        }

        if (this.teams.team2.color == 'purple') {
            img2 =  `<div class="elipses e-purple" style="height:35px;width:35px"><img src="${piezaPurple}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team2.color == 'blue') {
            img2 =  `<div class="elipses e-blue" style="height:35px;width:35px"><img src="${piezaBlue}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team2.color == 'green') {
            img2 =  `<div class="elipses e-green" style="height:35px;width:35px"><img src="${piezaGreen}" style="height:35px;margin-top:10px!important"></div>`;
        } else if(this.teams.team2.color == 'red') {
            img2 =  `<div class="elipses e-red" style="height:35px;width:35px"><img src="${piezaRed}" style="height:35px;margin-top:10px!important"></div>`;
        }
        


        $(`#position${position1}`).append(`${img1}`);
        $(`#position${position2}`).append(`${img2}`);
    }
}
</script>
<style scoped>

.tablero {
    position: absolute;
    top: 100px;
    margin-top: 35px;
    width: 700px;
    height: 700px;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: repeat(8,1fr);
    background-color: #fff;
    border:dotted thin black;
}



.tablero2 {
    position: absolute;
    top: 202px;
    margin-top: 35px;
    width: 500px;
    height: 500px;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-template-rows: repeat(6,1fr);
    background-color: #fff;
    border: dotted thin black;
}

.a{
    background: radial-gradient(#DED0B2 10%, #C7A758);
    border-style: solid;
    border-color: #C7A758;
   
}

.tablero3 {
    position: absolute;
    top: 302px;
    margin-top: 35px;
    margin-bottom: 35px;
    width: 300px;
    height: 300px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(4,1fr);
    background-color: #fff;
    border: dotted thin black;
}

.a{
    background: radial-gradient(#DED0B2 10%, #C7A758);
    border-style: solid;
    border-color: #C7A758;
   
}

.b{
    background: radial-gradient(#DED0B2 10%, #6EAAC8);
    border-style: solid;
    border-color: #6EAAC8;
    max-height: 90px;
}

.c{
    background: radial-gradient(#DED0B2 10%, #57CF67);
    border-style: solid;
    border-color: #57CF67;
   
}

</style>
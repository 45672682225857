<template>
    <div class="w-100 d-flex justify-content-center mt-3 mb-3">
        <div class="row footer">
            <div class="col-md-6">
                <img :src="logoEurope" class="img-fluid">
            </div>
            <div class="col-md-6">
                <img :src="logoTurkey" class="img-fluid">
            </div>
        </div>
    </div>

</template>
<style scoped>
img {
    width: 120px;
}
</style>
<script>
import logoTurkey from '@/assets/img/logo1.png';
import logoEurope from '@/assets/img/ua_logo.png';
export default {
    name:'Footer',
    data: () => ({
        logoEurope,
        logoTurkey
    }),
}
</script>
<template>
  <div id="app">
    <game-view-pm />
  </div>
</template>

<script>
import GameViewPm from './views/GameView.vue';
export default {
  name: 'App',
  components: {
    GameViewPm,
  }
}
</script>


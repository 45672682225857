<template>
  <div>
    <div class="d-flex justify-content-around flags">
        <img :src="usaFlagImg" @click="selectLenguage('english')" :class="teams.lenguage == 'english' ? 'selected' : ''">
        <img :src="spainFlagImg" @click="selectLenguage('spanish')" :class="teams.lenguage == 'spanish' ? 'selected' : ''">
        <img :src="turkeyFlagImg" @click="selectLenguage('turkish')" :class="teams.lenguage == 'turkish' ? 'selected' : ''">
        <img :src="greceFlagImg" @click="selectLenguage('grece')" :class="teams.lenguage == 'grece' ? 'selected' : ''">
    </div>

    <div id="google_translate_element2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//IMG 
import altavozImg from '@/assets/img/altavoz.png';
import usaFlagImg from '@/assets/img/usa-flag.png';
import spainFlagImg from '@/assets/img/spain-flag.png';
import turkeyFlagImg from '@/assets/img/turkey-flag.png';
import greceFlagImg from '@/assets/img/grece-flag.png';

export default {
  name: 'Translator',
  props: {
    teams: {
        type:Array,
    },
    countries: {
      type: Array,
      default() {
        return this.defaultCountries;
      },
    },
  },
  data() {
    return {
        altavozImg,
        usaFlagImg,
        spainFlagImg,
        turkeyFlagImg,
        greceFlagImg,
        defaultCountries: [
            {
            code: 'en|af',
            title: 'Afrikaans',
            },
            {
            code: 'en|sq',
            title: 'Albanian',
            },
            {
            code: 'en|ar',
            title: 'Arabic',
            },
            {
            code: 'en|hy',
            title: 'Armenian',
            },
            {
            code: 'en|az',
            title: 'Azerbaijani',
            },
            {
            code: 'en|eu',
            title: 'Basque',
            },
            {
            code: 'en|be',
            title: 'Belarusian',
            },
            {
            code: 'en|bg',
            title: 'Bulgarian',
            },
            {
            code: 'en|ca',
            title: 'Catalan',
            },
            {
            code: 'en|zh-CN',
            title: 'Chinese (Simplified)',
            },
            {
            code: 'en|zh-TW',
            title: 'Chinese (Traditional)',
            },
            {
            code: 'en|hr',
            title: 'Croatian',
            },
            {
            code: 'en|cs',
            title: 'Czech',
            },

            {
            code: 'en|da',
            title: 'Danish',
            },
            {
            code: 'en|nl',
            title: 'Dutch',
            },
            {
            code: 'en|en',
            title: 'English',
            },
            {
            code: 'en|et',
            title: 'Estonian',
            },
            {
            code: 'en|tl',
            title: 'Filipino',
            },
            {
            code: 'en|fi',
            title: 'Finnish',
            },
            {
            code: 'en|fr',
            title: 'French',
            },

            {
            code: 'en|de',
            title: 'German',
            },
            {
            code: 'en|el',
            title: 'Greek',
            },
            {
            code: 'en|hu',
            title: 'Hungarian',
            },
            {
            code: 'en|id',
            title: 'Indonesian',
            },
            {
            code: 'en|ga',
            title: 'Irish',
            },
            {
            code: 'en|it',
            title: 'Italian',
            },
            {
            code: 'en|ja',
            title: 'Japanese',
            },
            {
            code: 'en|ko',
            title: 'Korean',
            },
            {
            code: 'en|lt',
            title: 'Lithuanian',
            },
            {
            code: 'en|ms',
            title: 'Malay',
            },
            {
            code: 'en|no',
            title: 'Norwegian',
            },
            {
            code: 'en|pl',
            title: 'Polish',
            },

            {
            code: 'en|pt',
            title: 'Portuguese',
            },
            {
            code: '"en|ro',
            title: 'Romanian',
            },
            {
            code: 'en|ru',
            title: 'Russian',
            },
            {
            code: 'en|es',
            title: 'Spanish',
            },
            {
            code: 'en|sv',
            title: 'Swedish',
            },
            {
            code: 'en|th',
            title: 'Thai',
            },
            {
            code: 'en|tr',
            title: 'Turkish',
            },
            {
            code: 'en|uk',
            title: 'Ukrainian',
            },
        ],
    };
  },
  computed: {
    hasClickListener() {
      return Object.keys(this.$listeners).includes('on-country-click');
    },
  },
  methods: {
    selectLenguage(lenguage) {
        this.teams.lenguage = lenguage;

        let code = '';

        if(lenguage == 'english') {
            code = "en|en";
        } else if(lenguage == 'grece') {
            code = "en|el";
        }else if(lenguage == 'turkish') {
            code = "en|tr";
        }else if(lenguage == 'spanish') {
            code = "en|es";
        }
        this.doTranslation(code);
    },
    doTranslation(code) {
      // ensure the code doesn't break on server side
      if (typeof window !== 'undefined') {
        window.doGTranslate(code);
        if (this.hasClickListener) {
          this.$emit('on-country-click');
        }
      }
    },
    checkIfCountryIsAvailableInDefaults(countryObject) {
      return this.defaultCountries.some(
        (country) =>
          country.code.toLowerCase() === countryObject.code.toLowerCase() &&
          country.title.toLowerCase() === countryObject.title.toLowerCase()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flags {
    img {
        width: 100px;
        height: 100px;
        cursor: pointer;
    }
}
.selected {
    border: 6px #F2AD81 solid;
    border-radius: 12px;
}
</style>
<style scoped>

a:link {
  text-decoration: none;
  font-size: large;
  cursor: pointer;
}
.language-item {
  display: flex;
}

.language__text {
  color: black;

  margin-top: 7px;
  padding-left: 5px;
  text-decoration: none;
}
.language-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.flag {
  height: 40px;
}

h3 {
  cursor: pointer;
}

/*//columns*/
/* Container for flexboxes */
.row {
  display: flex;
  flex-wrap: wrap;
}

/* Create four equal columns */
.column {
  flex: 20%;
  padding: 10px;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .column {
    flex: 25%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
}
</style>

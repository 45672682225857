<template>
    <div>
        <footer-pm></footer-pm>
        <div class="content-game container">
            <div class="row">
                <!--TEAMS INFO-->
                <div class="d-flex justify-content-between text-start info">
                        <div class="col-2" v-if="teams.team1.name != ''"> 
                            <div class="card">
                                <div class="card-header justify-content-center d-flex align-items-center">
                                    <p><strong> {{ teams.team1.name }}</strong></p>  
                                    
                                    <div>       
                                        <div>
                                            <div class="elipses e-purple"
                                                v-if="teams.team1.color == 'purple' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaPurple" style="margin-top: 13px!important;">
                                            </div>

                                            <div class="elipses e-blue" 
                                                v-else-if="teams.team1.color == 'blue' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                            </div>

                                            <div class="elipses e-green"
                                                v-else-if="teams.team1.color == 'green' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaGreen" style="margin-top: 13px!important;">
                                            </div>
                                            
                                            <div class="elipses e-red"
                                                v-else-if="teams.team1.color == 'red' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaRed" style="margin-top: 13px!important;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            
                        </div>
                                    
                        <div class="col-2" v-if="teams.team2.name != ''">
                            <div class="card">
                                <div class="card-header justify-content-center d-flex align-items-center">
                                    <p><strong> {{ teams.team2.name }}</strong></p>  
                                    
                                    <div>       
                                        <div>
                                            <div class="elipses e-purple"
                                                v-if="teams.team2.color == 'purple' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaPurple" style="margin-top: 13px!important;">
                                            </div>

                                            <div class="elipses e-blue" 
                                                v-else-if="teams.team2.color == 'blue' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                            </div>

                                            <div class="elipses e-green"
                                                v-else-if="teams.team2.color == 'green' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaGreen" style="margin-top: 13px!important;">
                                            </div>
                                            
                                            <div class="elipses e-red"
                                                v-else-if="teams.team2.color == 'red' "
                                                style=" height: 45px;
                                                        width: 50px;">
                                                <img :src="piezaRed" style="margin-top: 13px!important;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                </div>
            </div>
            <welcome-pm :teams="teams" v-if="!teams.status" @changeVolumen="changeVolumen"/>
            <game :teams="teams" v-if="teams.status && !teams.boardView && !teams.ruleteMode && !teams.questionView"/>
            <board-game :teams="teams" v-if="teams.status && teams.boardView && !teams.ruleteMode && !teams.questionView" />
            <rulete :teams="teams" v-if="teams.status && !teams.boardView && teams.ruleteMode && !teams.questionView"  /> 
            <questions :teams="teams" v-if="teams.status && !teams.boardView && !teams.ruleteMode && teams.questionView"  /> 

        </div>
|
        <div>
            <audio ref="audioElement" :src="SonidoGame" @ended="repetirCancion" @loadedmetadata="iniciarReproduccion"></audio>
        </div>
    </div>
</template>
<style lang="scss">
@import '@/assets/css/main.scss';
.skiptranslate {
    display: none!important;
}
</style>
<script>

//music 
import SonidoGame from '@/assets/sonido/music.mp3';

//IMG
import piezaPurple from "@/assets/img/item-purple.png";
import piezaBlue from "@/assets/img/item-blue.png";
import piezaRed from "@/assets/img/item-red.png";
import piezaGreen from "@/assets/img/item-green.png";

//views
import WelcomePm from '@/components/Welcome.vue';
import Game from '@/components/Game.vue';
import BoardGame from '@/components/BoardGame.vue';
import Rulete from '@/components/Rulete.vue';
import Questions from "@/components/Questions.vue";

//layouts
import FooterPm from '@/components/Footer.vue';


export default {
    name: 'GameView',
    data: () => ({
        SonidoGame,
        teams: {
            team1: {
                name: '',
                color: 'blue',
                position: 1,
                turno: true
            },
            team2: {
                name: '',
                color: 'purple',
                position: 1,
                turno: false
            },
            status: false,
            boardView: false,
            ruleteMode: false,
            questionView: false,
            rulete:null,
            questionSelected: null,
            lenguage: 'english',
        },
        piezaPurple,
        piezaBlue,
        piezaRed,
        piezaGreen

    }),
    mounted() {
        document.addEventListener("click", this.iniciarReproduccion, { once: true });

        const data = JSON.parse(localStorage.getItem("DataGame"));

        if(data) {
            this.teams = data;
        } else {
            localStorage.setItem("DataGame", JSON.stringify(this.teams));
        }
    },
    methods: {
        changeVolumen(nuevoVolumen) {
            var audioElement = this.$refs.audioElement;
            audioElement.volume = nuevoVolumen;
        },
        iniciarReproduccion() {
            var audioElement = this.$refs.audioElement;
            audioElement.play().catch(error => {
                console.log("Error al iniciar la reproducción automática:", error);
            });
        },
        repetirCancion() {
            var audioElement = this.$refs.audioElement;
            audioElement.currentTime = 0;
            audioElement.play();
        },
    },
    components: {
        WelcomePm,
        Game,
        BoardGame,
        Rulete,
        Questions,
        FooterPm,
    }

}
</script>
<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="question-back"></div>
                <div class="question d-flex justify-content-center">
                    <div class="question-body">
                        <h1>
                            {{ questionSelected.name }}
                        </h1>
                        <p>
                            {{ questionSelected.options[randomNumberQuestion].nameQuestion }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="position: relative;
                                        top: 250px;
                                        bottom: 0;">
                <div class="row" style="margin-top: 188px;">
                    <div class="col-md-6" v-for="(answer, index) in shuffleArray(questionSelected.options[randomNumberQuestion].answers)"
                            :key="index">
                        <div style="margin-top: 10px;" class="d-flex justify-content-center align-items-end" @click="checkAnswer(answer)" 
>
                            <p style="width: 40px;"> {{ index + 1 }} )</p>
                            <div class="answer">
                                <p style="font-size: 1.2rem;
                                        text-align: center;">
                                    {{answer}}
                                </p>
                                
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Wrong Answer -->
        <div class="modal fade" id="setModalWrongAnswer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="selectName" class="player-name">
                            Wrong Answer
                        </label>
                        <img :src="wrongAwnser">
                        
                    </div>

                </div>
                <div class="modal-footer" style="justify-content:center!important;;">
                <button type="button" class="btn btn-primary" @click="tryAgain"
                style="font-size: 35px!important;
                        height: 50px!important;
                        width: 217px!important;
                        line-height: 34px!important;"
                >Continuar</button>
            </div>

                </div>
            </div>
        </div>

        <!-- Modal Correct Answer -->
        <div class="modal fade" id="setModalRightAnswer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="form-group">
                    <label for="selectName" class="player-name">
                        Correct Answer
                    </label>
                    <img :src="rightAwnser">
                    </div>

                </div>
                <div class="modal-footer" style="justify-content:center!important;;">
                <button type="button" class="btn btn-primary" @click="goToBack"
                style="font-size: 35px!important;
                        height: 50px!important;
                        width: 217px!important;
                        line-height: 34px!important;"
                >Continuar</button>
            </div>
            </div>
        </div>

        |
        <div>
            <audio ref="audioElement"></audio>
        </div>

        </div>
    </div>
</template>
<style lang="scss">
.answer {
    cursor: pointer;
    width: 420px;
    height: fit-content;
    min-height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}
.question-back {
    width: 80%;
    position: absolute;
    top: 154px;
    right: 0;
    left: 163px;
    height: 55vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transform: rotate(-2.57deg);
}
.question {
    width: 80%;
    position: absolute;
    top: 137px;
    right: 0;
    left: 0;
    padding: 25px;
    height: 55vh;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: start;
        }

        p {
            text-align: center;
        }
    }

}

</style>

<script>
const randomNumberQuestion = Math.floor(Math.random() * 10);
import questions from '@/core/questions';
import rightAwnser from '@/assets/img/flame-success.gif';
import wrongAwnser from '@/assets/img/machine.gif';

//SONIDO

import correctSound from '@/assets/sonido/correct.mp3';
import wrongSound from '@/assets/sonido/wrong.mp3';

export default {
    props: ['teams'],
    data: () => ({
        questionSelected: {
            name: '',
            questions: []
        },
        randomNumberQuestion,
        rightAwnser,
        wrongAwnser,
        correctSound,
        wrongSound
    }),
    methods: {
        shuffleArray(array) {
            const newArray = [...array]
            const length = newArray.length

            for (let start = 0; start < length; start++) {
                const randomPosition = Math.floor((newArray.length - start) * Math.random())
                const randomItem = newArray.splice(randomPosition, 1)

                newArray.push(...randomItem)
            }

            return newArray
        },
        tryAgain() {
            $("#setModalWrongAnswer").modal('hide');
        },
        goToBack() {
            this.teams.questionView = false;
            $("#setModalRightAnswer").modal('hide');
        },
        reproducirSonidos(type) {
            var audioElement = this.$refs.audioElement;

            if(type == 'right') {
                audioElement.src = this.correctSound;
            } else {
                audioElement.src = this.wrongSound;
            }   

            audioElement.play().catch(error => {
                console.log("Error al iniciar la reproducción automática:", error);
            });

        },
        checkAnswer(answer) {
            if(answer === this.questionSelected.options[randomNumberQuestion].answerCorect) {
                if(this.teams.team1.turno) {
                    this.teams.team1.position += 1;
                    
                    localStorage.setItem("DataGame", JSON.stringify(this.teams));

                } else {
                    this.teams.team2.position += 1;
                }
                $("#setModalRightAnswer").modal('show');
                this.reproducirSonidos('right');
            } else {
                $("#setModalWrongAnswer").modal('show');
                this.reproducirSonidos('wrong');
            }

            this.teams.team1.turno = !this.teams.team1.turno;
            this.teams.team2.turno = !this.teams.team2.turno;

        }
    },
    mounted() {
        const position = !this.teams.rulete ? 1 : 0;
        this.questionSelected = questions[position].filter(question => question.id  == this.teams.questionSelected);
        this.questionSelected = this.questionSelected[0];
    }
}
</script>
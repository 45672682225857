<template>
    <div class="config">
        
        <img :src="configImg"  @click="openConfigModal" class="configImg">

        <!-- Modal Config -->
        <div class="modal fade" id="setModalConfig" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="selectName" class="player-name">
                            Sound
                        </label>
                        <div class="d-flex w-100 justify-content-around">
                            <img :src="altavozImg" style="width: 70px;">
                            <input type="range" class="input-range" v-model="volumen" min="0" max="1" step="0.1" >
                        </div>
                        
                    </div>

                </div>
                <div class="form-group">
                    <label for="selectName" class="player-name">
                        Lenguages
                    </label>
                    <Translator :countries="countries" @on-country-click="hideModal" id="translator" :teams="teams"/>
                </div>

                
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .selected {
        border: 6px #F2AD81 solid;
        border-radius: 12px;
    }
    .input-range {
        width: 80%;
    }

    .config {
        display: flex;
        justify-content: flex-end;

        .configImg {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
</style>
<script>

//IMG 
import configImg from '@/assets/img/config.png';
import altavozImg from '@/assets/img/altavoz.png';

import  Translator  from "@/components/Translator.vue";

export default {
    props: ["teams"],
    data: () => ({
        configImg,
        altavozImg,
        volumen: 1,
        countries: [
            {
            code: "en|el",
            title: "Greek",
            },
            {
            code: "en|en",
            title: "English",
            },
            {
            code: "en|tr",
            title: "Turkish",
            },
            {
            code: "en|es",
            title: "Spanish",
            },
        ],
    }),
    components: {
        Translator,
    },
    watch: {
        volumen: function (nuevoVolumen) {
            this.$emit("changeVolumen", nuevoVolumen);
        },
    },
    methods : {
        openConfigModal() {
            $("#setModalConfig").modal('show');
        }
    }
}
</script>
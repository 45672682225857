<template>
    <div class="col-auto text-center">
        <div class="row">
            
            <div class="col-12 ">
                <div 
                @click="changeBoardView"
                style="height: 80vh;margin-top: 250px;cursor:pointer">


                    <pyramid />
                </div>
                <!--BEGIN TEAM1-->
                <div class="positions">

               
                <div v-if="teams.team1.position < 28">                                
                    <div class="avatar-teams_team1">       
                        <div>
                            <div class="elipses e-purple"
                                v-if="teams.team1.color == 'purple' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaPurple" style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-blue" 
                                v-else-if="teams.team1.color == 'blue' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaBlue"  style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-green"
                                v-else-if="teams.team1.color == 'green' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaGreen" style="margin-top: 13px!important;">
                            </div>
                            
                            <div class="elipses e-red"
                                v-else-if="teams.team1.color == 'red' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaRed" style="margin-top: 13px!important;">
                            </div>
                        </div>
                    </div>
                    <div class="position-teams_team1"></div>
                </div>

                <div v-if="teams.team1.position >= 28 && teams.team1.position  < 48">                                
                    <div class="avatar2-teams_team1">       
                        <div>
                            <div class="elipses e-purple"
                                v-if="teams.team1.color == 'purple' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaPurple" style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-blue" 
                                v-else-if="teams.team1.color == 'blue' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaBlue"  style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-green"
                                v-else-if="teams.team1.color == 'green' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaGreen" style="margin-top: 13px!important;">
                            </div>
                            
                            <div class="elipses e-red"
                                v-else-if="teams.team1.color == 'red' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaRed" style="margin-top: 13px!important;">
                            </div>
                        </div>
                    </div>
                    <div class="position2-teams_team1"></div>
                </div>

                <div v-if="teams.team1.position  >= 48">                                
                    <div class="avatar3-teams_team1">       
                        <div>
                            <div class="elipses e-purple"
                                v-if="teams.team1.color == 'purple' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaPurple" style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-blue" 
                                v-else-if="teams.team1.color == 'blue' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaBlue"  style="margin-top: 13px!important;">
                            </div>

                            <div class="elipses e-green"
                                v-else-if="teams.team1.color == 'green' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaGreen" style="margin-top: 13px!important;">
                            </div>
                            
                            <div class="elipses e-red"
                                v-else-if="teams.team1.color == 'red' "
                                style=" height: 45px;
                                        width: 50px;">
                                <img :src="piezaRed" style="margin-top: 13px!important;">
                            </div>
                        </div>
                    </div>
                    <div class="position3-teams_team1"></div>
                </div>

                <!--END TEAM1-->

                <!--BEGIN TEAM2-->

                <div v-if="teams.team2.position < 28">
                    <div class="avatar-teams_team2">       
                                <div>
                                    <div class="elipses e-purple"
                                        v-if="teams.team2.color == 'purple' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaPurple" style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-blue" 
                                        v-else-if="teams.team2.color == 'blue' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-green"
                                        v-else-if="teams.team2.color == 'green' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaGreen" style="margin-top: 13px!important;">
                                    </div>
                                    
                                    <div class="elipses e-red"
                                        v-else-if="teams.team2.color == 'red' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaRed" style="margin-top: 13px!important;">
                                    </div>
                                </div>
                    </div>
                    <div class="position-teams_team2 position1"></div>
                </div>

                <div v-if="teams.team2.position >= 28 && teams.team1.position  < 48">
                    <div class="avatar2-teams_team2">       
                                <div>
                                    <div class="elipses e-purple"
                                        v-if="teams.team2.color == 'purple' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaPurple" style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-blue" 
                                        v-else-if="teams.team2.color == 'blue' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-green"
                                        v-else-if="teams.team2.color == 'green' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaGreen" style="margin-top: 13px!important;">
                                    </div>
                                    
                                    <div class="elipses e-red"
                                        v-else-if="teams.team2.color == 'red' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaRed" style="margin-top: 13px!important;">
                                    </div>
                                </div>
                    </div>
                    <div class="position2-teams_team2"></div>
                </div>

                <div v-if="teams.team2.position  >= 48">
                    <div class="avatar3-teams_team2">       
                                <div>
                                    <div class="elipses e-purple"
                                        v-if="teams.team2.color == 'purple' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaPurple" style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-blue" 
                                        v-else-if="teams.team2.color == 'blue' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                    </div>

                                    <div class="elipses e-green"
                                        v-else-if="teams.team2.color == 'green' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaGreen" style="margin-top: 13px!important;">
                                    </div>
                                    
                                    <div class="elipses e-red"
                                        v-else-if="teams.team2.color == 'red' "
                                        style=" height: 45px;
                                                width: 50px;">
                                        <img :src="piezaRed" style="margin-top: 13px!important;">
                                    </div>
                                </div>
                    </div>
                    <div class="position3-teams_team2"></div>
                </div>


                <!--END TEAM2-->
            </div>

            </div>

            <div class="d-flex justify-content-between ruletes">
                <img :src="Ruleta1Img" class="ruletes-img" @click="changeRuleteView(false)">
                <img :src="Ruleta2Img" class="ruletes-img" @click="changeRuleteView(true)">
            </div>
        </div>

        <!-- Modal Winner -->
        <div class="modal fade" id="winnerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                <div class="modal-body">
                    <div class="d-flex justify-content-center align-items-center flex-column" style="height: 90vh;">

                        <h1 style="font-size: 3.5rem;">
                            Congratulations!!
                            You Win
                            <br>
                            <span style="font-size: 3.5rem;,margin-top: 10px;" v-if="teams.team1.position === 60" >
                                {{ teams.team1.name }}
                            </span>

                            <span style="font-size: 3.5rem;,margin-top: 10px;" v-else>
                                {{ teams.team2.name }}
                            </span>
                        </h1>

                        <div class="avatar-teams_team1" v-if="teams.team1.position === 60" style="position: fixed;top:top: 290px;left: 830px;">       
                            <div>
                                <div class="elipses e-purple"
                                    v-if="teams.team1.color == 'purple' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaPurple" style="margin-top: 13px!important;">
                                </div>

                                <div class="elipses e-blue" 
                                    v-else-if="teams.team1.color == 'blue' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                </div>

                                <div class="elipses e-green"
                                    v-else-if="teams.team1.color == 'green' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaGreen" style="margin-top: 13px!important;">
                                </div>
                                
                                <div class="elipses e-red"
                                    v-else-if="teams.team1.color == 'red' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaRed" style="margin-top: 13px!important;">
                                </div>
                            </div>
                        </div>

                        <div class="avatar-teams_team2" v-else style="position: fixed;top:top: 290px;left: 830px;">       
                            <div>
                                <div class="elipses e-purple"
                                    v-if="teams.team2.color == 'purple' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaPurple" style="margin-top: 13px!important;">
                                </div>

                                <div class="elipses e-blue" 
                                    v-else-if="teams.team2.color == 'blue' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaBlue"  style="margin-top: 13px!important;">
                                </div>

                                <div class="elipses e-green"
                                    v-else-if="teams.team2.color == 'green' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaGreen" style="margin-top: 13px!important;">
                                </div>
                                
                                <div class="elipses e-red"
                                    v-else-if="teams.team2.color == 'red' "
                                    style=" height: 150px;
                                            width: 150px;">
                                    <img :src="piezaRed" style="margin-top: 13px!important;">
                                </div>
                            </div>
                        </div>
                        <br>

                       



                        <div class="pyro" style="position: absolute;">
                        <div class="before"></div>
                        <div class="after"></div>
                        </div>

                    <img :src="Cups" class="img-fluid" style="position:absolute;top:0;left: 0;">
                    <img :src="Cups" class="img-fluid" style="position:absolute;top:0;right: 0;">


                    <img :src="Cyborg" class="img-fluid" style="margin-top: 140px;">


                    </div>
                </div>
                <div class="modal-footer" style="justify-content:center!important;;">
                <button type="button" class="btn btn-primary"
                @click="restarGame"
                style="font-size: 35px!important;
                        height: 50px!important;
                        width: 217px!important;
                        line-height: 34px!important;z-index:300"
                >Restar Game</button>
            </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<style lang="scss" scoped>
$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}

body {
  margin:0;
  padding:0;
  background: #000;
  overflow: hidden;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}

</style>
<style scoped>


/* #Dispositivo = Tablets de baja resolución y teléfonos Móviles (horizontal) 
#Resolución = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 1570px) {
    .positions {
        display:none;
    }

}
 
 


.avatar-teams_team1{
    position: absolute;
    left: 172px;
    right: 0;
    bottom: 0px;
    top: 403px;
    z-index: 223;
}


.position-teams_team1 {
    position: absolute;
    width: 500px;
    transform: rotate(211deg);
    height: 2px;
    background: #00000082;
    left: 172px;
    right: 0;
    bottom: 0px;
    top: 562px;
    z-index: 222;
}


.avatar2-teams_team1{
    position: absolute;
    left: 258px;
    right: 0;
    bottom: 0px;
    top: 290px;
    z-index: 223;
}

.position2-teams_team1 {
    position: absolute;
    width: 500px;
    transform: rotate(211deg);
    height: 2px;
    background: #00000082;
    left: 262px;
    right: 0;
    bottom: 0px;
    top: 441px;
    z-index: 222;
}

.avatar3-teams_team1{
    position: absolute;
    left: 376px;
    right: 0;
    bottom: 0px;
    top: 185px;
    z-index: 223;
}

.position3-teams_team1 {
    position: absolute;
    width: 500px;
    transform: rotate(211deg);
    height: 2px;
    background: #00000082;
    left: 374px;
    right: 0;
    bottom: 0px;
    top: 345px;
    z-index: 222;
}


.avatar-teams_team2{
    position: absolute;
    right: 217px;
    bottom: 0px;
    z-index: 223;
    top: 392px;
}
.position-teams_team2 {
    position: absolute;
    width: 500px;
    transform: rotate(326deg);
    height: 2px;
    background: #00000082;
    right: 221px;
    bottom: 0px;
    top: 562px;
    z-index: 222;
}

.avatar2-teams_team2{
    position: absolute;
    right: 286px;
    bottom: 0px;
    z-index: 223;
    top: 220px;
}
.position2-teams_team2 {
    position: absolute;
    width: 500px;
    transform: rotate(326deg);
    height: 2px;
    background: #00000082;
    right: 286px;
    bottom: 0px;
    top: 399px;
    z-index: 222;
}

.avatar3-teams_team2{
    position: absolute;
    right: 399px;
    bottom: 0px;
    z-index: 223;
    top: 119px;
}
.position3-teams_team2 {
    position: absolute;
    width: 500px;
    transform: rotate(326deg);
    height: 2px;
    background: #00000082;
    right: 386px;
    bottom: 0px;
    top: 294PX;
    z-index: 222;
}
.ruletes-img {
    cursor:pointer;
    width: 280px;
    height: 280px;
    z-index: 224;
}
.ruletes {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
</style>

<script>
//IMG
import piezaPurple from "@/assets/img/item-purple.png";
import piezaBlue from "@/assets/img/item-blue.png";
import piezaRed from "@/assets/img/item-red.png";
import piezaGreen from "@/assets/img/item-green.png";

import Cups from "@/assets/img/cups.gif";
import Cyborg from "@/assets/img/cyborg-117.gif";


import Ruleta1Img from '@/assets/img/ruletaRosada.png';
import Ruleta2Img from '@/assets/img/ruletaGreen.png';


import Pyramid from './Pyramid.vue';
export default {
    props: ['teams'],
    name: 'Game',
    components: {Pyramid},
    data: () => ({
        Ruleta1Img,
        Ruleta2Img,
        piezaPurple,
        piezaBlue,
        piezaRed,
        piezaGreen,
        Cyborg,
        Cups,
        formatCleanTeams:{
            team1: {
                name: '',
                color: 'blue',
                position: 59,
                turno: true
            },
            team2: {
                name: '',
                color: 'purple',
                position: 20,
                turno: false
            },
            status: false,
            boardView: false,
            ruleteMode: false,
            questionView: false,
            rulete:null,
            questionSelected: null,
            lenguage: 'english',
        },
    }),
    mounted() {
        if(this.teams.team1.position === 60 || this.teams.team2.position === 60) {
            $("#winnerModal").modal('show');
        }   
        
    },
    methods: {
        restarGame() {
            localStorage.setItem("DataGame", JSON.stringify(this.formatCleanTeams));
            location.reload();

        },  
        changeBoardView() {
            this.teams.boardView = true;
        },
        spinRulete() {
            this.teams.ruleteMode  = true;
        },
        changeRuleteView(typeRulete) {
            this.teams.ruleteMode = true;
            this.teams.rulete = typeRulete;
        }
    }
}
</script>